.career-title {
  height: 400px;
  position: relative;
  background-repeat: no-repeat;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0.96) 20%, rgba(255, 255, 255, 0.1) 80%);
    z-index: -1;
  }

  .header-container {
    height: 100%;
    .title-content {
      height: 100%;

      .page-title{
        z-index: 5;

        h1{
          color: #1C2539;
          font-size: 55px;
          font-weight: 700;
        }
      }
    }
  }
}
