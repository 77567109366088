.about-us {
  .container {
    .row {
      .welcome {
        .subtitle {
          font-family: 'Red Hat Display', sans-serif;
          font-size: 16px;
          font-weight: 500;
          text-transform: uppercase;
          color: #085e99;
        }

        .title {
          font-family: 'Red Hat Display', sans-serif;
          font-size: 46px;
          font-weight: 700;
          color: #1c2539;
        }
      }

      .about-info {
        p {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #5d666f;
          text-align: justify;
        }
      }
    }

    .mission {
      margin-top: 70px;

      .left {
        img {
          width: 100%;
        }
      }

      .right {
        h2 {
          font-family: 'Red Hat Display', sans-serif;
          font-size: 46px;
          font-weight: 700;
          color: #1c2539;
        }

        .quote {
          margin-top: 30px;
          display: flex;
          justify-content: space-around;

          p {
            font-family: 'Nunito Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #5d666f;
            width: 72%;
            margin-top: 30px;
            text-align: justify;
          }

          .quote-open {
            font-size: 80px;
            color: #085f997c;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            margin-top: -30px;
          }

          .quote-close {
            font-size: 80px;
            color: #085f997c;
            -webkit-transform: scaleX(-1);
            transform: scaleY(-1);
            margin-top: 60px;
          }
        }
      }
    }

    .vision {
      margin-top: 70px;

      .left {
        h2 {
          font-family: 'Red Hat Display', sans-serif;
          font-size: 46px;
          font-weight: 700;
          color: #1c2539;
        }

        .quote {
          margin-top: 30px;
          display: flex;
          justify-content: space-around;

          p {
            font-family: 'Nunito Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #5d666f;
            width: 72%;
            margin-top: 30px;
            text-align: justify;
          }

          .quote-open {
            font-size: 80px;
            color: #085f997c;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            align-self: flex-start;
          }

          .quote-close {
            font-size: 80px;
            color: #085f997c;
            -webkit-transform: scaleX(-1);
            transform: scaleY(-1);
            align-self: flex-end;
          }
        }
      }

      .right {
        img {
          width: 100%;
        }
      }
    }

    .values {
      margin-top: 70px;

      .left {
        img {
          width: 100%;
        }
      }

      .right {
        h2 {
          font-family: 'Red Hat Display', sans-serif;
          font-size: 46px;
          font-weight: 700;
          color: #1c2539;
        }

        .quote {
          margin-top: 30px;
          display: flex;
          justify-content: space-around;

          p {
            font-family: 'Nunito Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #5d666f;
            width: 72%;
            margin-top: 30px;
            text-align: justify;
          }

          .quote-open {
            font-size: 80px;
            color: #085f997c;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            align-self: flex-start;
          }

          .quote-close {
            font-size: 80px;
            color: #085f997c;
            -webkit-transform: scaleX(-1);
            transform: scaleY(-1);
            align-self: flex-end;
          }
        }
      }
    }

    .history {
      margin-top: 70px;

      h2 {
        font-family: 'Red Hat Display', sans-serif;
        font-size: 46px;
        font-weight: 700;
        color: #1c2539;
      }

      p {
        font-family: 'Nunito Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #5d666f;
        margin-top: 30px;
        text-align: justify;
      }
    }
  }
}

.our-team {
  .container {
    // margin-top: 70px;
    margin-bottom: 70px;

    .title {
      font-family: 'Red Hat Display', sans-serif;
      font-size: 46px;
      font-weight: 700;
      color: #1c2539;
      text-align: center;
      position: relative;
      margin-bottom: 50px;

      &::after {
        content: '';
        width: 130px;
        height: 4px;
        background-color: #085e99;
        position: absolute;
        top: 80px;
        transform: translateX(-50%);
        left: 50%;
      }
    }

    .card-container {
      margin-top: 50px;
      margin: 15px 0;

      .card-box {
        .box {
          position: relative;
          width: 315px;
          height: 310px;
          padding: 23px;

          img {
            width: 99%;
          }

          .team-info {
            width: 80%;
            position: absolute;
            background: white;
            opacity: 0.7;
            transform: translateX(-50%);
            bottom: 30px;
            left: 50%;
            padding: 10px;
            transition: 0.3s;

            h3 {
              font-size: 20px;
              font-weight: 700;
              text-align: center;
              color: #1c2539;
            }

            h4 {
              font-size: 16px;
              font-weight: 500;
              text-align: center;
              color: #085e99;
            }
          }

          &:hover {
            .team-info {
              opacity: 1;
              background: #085e99;
              width: 84%;

              h3,
              h4 {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.team-details {
  .details {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .details-box {
    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
      }

      h3 {
        font-size: 36px;
        font-weight: 500;
        color: #1c2539;
        margin-top: 20px;
        text-align: center;
      }

      h5 {
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        color: #085e99;
        text-align: center;
      }
    }

    .right{

      p {
            font-family: 'Nunito Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            color: #5d666f;
            text-align: justify;
          }

    }
  }
}
