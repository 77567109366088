nav {
  .menu-item {
    width: 90%;
    background: #fcfcfc;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px;
    transition: 0.3s;

    a {
      font-size: 16px;
      font-weight: 600;
      color: #1c2539;
      text-decoration: none;
      width: 100%;
      display: flex;
      align-items: center;
      transition: 0.3s;
      position: relative;

      span {
        fill: #085e99;
        margin-right: 10px;
      }

      .chev {
        position: absolute;
        right: 0;
      }

      // &:hover{
      //   color: white;

      //   span{
      //     fill: white;
      //   }
      // }
    }
    &:hover {
      background: #085e99;

      a {
        color: white;

        span {
          fill: white;
        }
      }
    }

    &.active {
      background: #085e99;
    }
  }

  .consultation {
    margin-top: 30px;
    width: 95%;
    height: 328px;
    position: relative;

    .consultation-message {
      background: rgba(2, 189, 43, 0.856);
      padding: 7px;
      position: absolute;
      right: 20px;
      top: 40px;

      .consultation-inner {
        border: #fff solid 1px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px;

        .left {
          display: flex;
          flex-direction: column;
          .call-box {
            color: #02bd2b;
            background: white;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-content: space-around;

          h4 {
            font-size: 12px;
            font-weight: 600;
            color: white;
            letter-spacing: -0.5px;
            margin-top: 10px;
          }

          h2 {
            font-size: 22px;
            font-weight: 600;
            color: white;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  nav {
    display: none;
  }
}
