.career{
  .career-main {
    margin-top: 70px;

    .career-first {
      .left {
        img {
          width: 100%;
        }
      }
      .right {
        h4 {
          font-size: 18px;
          font-weight: 600;
          color: #1c2539;
          margin-top: 50px;
          margin-bottom: 20px;
        }

        p {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          line-height: 28px;
          text-align: justify;
          align-self: flex-end;
        }

        ul {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          text-align: justify;
        }
      }
    }

    .next {
      h4 {
          font-size: 18px;
          font-weight: 600;
          color: #1c2539;
          margin-top: 50px;
          margin-bottom: 20px;
        }

      p {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          line-height: 28px;
          text-align: justify;
          align-self: flex-end;
        }

        ul {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          text-align: justify;
          margin-left: 40px;
        }
    }
  }
}