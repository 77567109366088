.breadcrums {
  max-width: 1200px;
  margin: 20px auto;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;

  & > *{
    display: inline-block;
    margin-right: 10px;
  }

  .crumb:after {
    content: '>';
    margin-left: 10px;
  }

  .crumb:last-child:after {
    display: none;
  }

  a {
    color: #085E99;
    text-decoration: none;

    &:hover {
      color: #02BD2B;
    }
  }

  .crumb a {
    color: #085E99;
    text-decoration: none;

    &:hover {
      color: #02BD2B;
    }
  }
}