footer {
  background: #085e99;
  z-index: -5;

  .footer-top-contents {
    display: flex;

    .left {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding: 20px;

      img {
        margin-right: 40px;
        width: 140px;
      }
    }

    .right {
      flex: 3;
      border-bottom-left-radius: 30px;
      position: relative;
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(2, 189, 43, 0.91);
        border-bottom-left-radius: 30px;
      }

      .growBigBox {
        z-index: 5;
        height: 100%;
        width: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        // d-flex justify-content-around align-items-center

        h3 {
          z-index: 10;
          font-size: 30px;
          font-weight: 700;
          color: white;
        }

        a {
          z-index: 10;
          font-size: 16px;
          font-weight: 700;
          text-decoration: none;
          color: #085e99;
          background: white;
          padding: 15px 30px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            color: #02bd2b;
          }
        }
      }
    }
  }

  .footer-bottom-contents {
    display: flex;
    .bottom-content {
      .row {
        margin-top: 30px;
        border-bottom: #a4a4a4 solid 1px;
        .card-box {
          .box {
            padding: 20px;

            h3 {
              font-size: 22px;
              font-weight: 600;
              color: white;
              position: relative;
              margin-bottom: 30px;

              &::after {
                position: absolute;
                content: '';
                background: #02bd2b;
                width: 38px;
                height: 4px;
                left: 0;
                bottom: -7px;
              }
            }

            p {
              font-family: 'Nunito Sans', sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 24.55px;
              color: white;
            }

            ul {
              list-style-type: none;
              margin-left: -20px;

              li {
                line-height: 45px;
                a {
                  color: white;
                  text-decoration: none;

                  &:hover {
                    color: #02bd2b;
                  }
                }
              }
            }
          }

          p {
            // border-bottom: #a4a4a4 1px solid;
            padding-bottom: 5px;
            display: flex;

            &:last-child {
              padding-bottom: none;
            }

            span {
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .copyright-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;

      .copyright {
        p {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 14px;
          font-weight: 600;
          color: white;
        }
      }

      .socials {
        .icon {
          color: white;
          // background: rgba(255, 255, 255, 0.356);
          margin-right: 15px;
          // padding: 2px;
          // border-radius: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  footer {
    .footer-top-contents {
      flex-direction: column;

      .left {
        flex: 1;
        display: flex;
        justify-content: center;
      }

      .right {
        flex: 1;

        &::before {
          border-bottom-left-radius: 0px;
        }

        .growBigBox {
          display: flex;
          flex-direction: column;
          padding: 20px;

          h3 {
            text-align: center;
          }
        }
      }
    }

    .copyright-box {
      flex-direction: column;
      align-items: center;
    }
  }
}
