.services {
  .services-container {
    .services-layout {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      nav {
        flex: 4;
      }

      main {
        flex: 8;
        margin-left: 20px;
        margin-bottom: 50px;

        .title {
          font-size: 40px;
          font-weight: 700;
          color: #1c2539;
        }

        .image-rep {
          img {
            width: 100%;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        p {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          line-height: 28px;
          text-align: justify;
        }

        h5 {
          font-size: 18px;
          font-weight: 600;
          color: #1c2539;
          margin-top: 50px;
          margin-bottom: 20px;
        }

        ul {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          text-align: justify;
        }
      }
    }
  }
}


.our-services {
  position: relative;
  z-index: 0;

  &::after {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    content: '';
    z-index: -1;
  }

  .container {
    z-index: 1;
    padding-top: 100px;
    padding-bottom: 50px;

    .row {
      .subtitle {
        font-family: 'Red Hat Display', sans-serif;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: #085e99;
        text-align: center;
      }

      .title {
        font-family: 'Red Hat Display', sans-serif;
        font-size: 46px;
        font-weight: 700;
        color: #1c2539;
        text-align: center;
        position: relative;

        &::after {
          content: '';
          width: 130px;
          height: 4px;
          background-color: #085e99;
          position: absolute;
          top: 80px;
          transform: translateX(-50%);
          left: 50%;
        }
      }
    }

    .card-container {
      margin-top: 50px;

      .card-box {
        
        .box {
          margin: 15px 0;
          background: white;
          padding: 23px;
          z-index: 8;
          box-shadow: 0 10px 20px rgba(137,151,186,.5);

          h3 {
            font-weight: 700;
            font-size: 24px;
            color: #1c2539;
            padding-top: 10px;
          }

          p {
            font-family: 'Nunito Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            color: #5d666f;
            text-align: justify;
          }

          a {
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 600;
            font-size: 16px;
            color: #085e99;
            text-decoration: none;
            display: flex;
            align-items: center;
            cursor: pointer;
            z-index: 130;
            width: fit-content;

            &:hover {
              color: #02bd2b;
            }

            span {
              background: white;
              border-radius: 50%;
              margin-right: 10px;
              padding: 5px 3px;
              width: 37px;
              height: 37px;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
          }
        }

        .box-extra {
          margin: 15px 0;
          padding: 70px 23px;
          position: relative;
          height: 92%;
          width: 100%;

          &::after {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(8, 94, 153, 0.86);
            content: '';
          }

          .content {
            position: absolute;
            z-index: 2;

            h3 {
              z-index: 4;
              font-size: 30px;
              font-weight: 700;
              color: white;
              margin-bottom: 68px;
            }

            a{
              background: white;
              font-size: 16px;
              font-weight: 700;
              text-decoration: none;
              color: #085E99;
              padding: 17px 35px;
              border-radius: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .row {
      .title {
        &::after {
          display: none;
        }
      }
    }
  }
}
