.main-navbar {
  background: white;

  img {
    height: 50px;
  }

  a.nav-link {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    color: #085e99;

    &:hover {
      color: #02bd2b;
    }
  }
  #main.active {
    color: red;
  }
  .dropdown {
    .dropdown-menu {
      display: none;
      transition: 1s;
    }

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
}
