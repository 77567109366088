.clientele {
  .clientele-main {
    margin-top: 70px;
    .box {
      .left {
        img {
          width: 100%;
        }
      }
      .right {
        display: flex;

        p {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          line-height: 28px;
          text-align: justify;
          align-self: flex-end;
        }
      }
    }

    .list {
      ul {
          font-family: 'Nunito Sans', sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: #5d666f;
          text-align: justify;
          margin-top: 20px;
          margin-left: 50px;
        }
    }
  }
}
