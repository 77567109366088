.carousel {
  .carousel-indicators {
    button {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #085e99;
    }
  }

  .carousel-inner {
    .carousel-item {
      .carousel-image {
        // z-index: 0;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.4);
        }
      }

      // .overlay-cover {
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   z-index: 0;
      //   background-color: #fff;
      //   opacity: 0.4;
      // }

      .carousel-text {
        height: 100%;
        padding-left: 10%;

        h3 {
          font-family: 'Red Hat Display', sans-serif;
          color: #1c2539;
          font-weight: 700;
          font-size: 66px;
          z-index: 5;
          text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);

          span {
            color: #085e99;
            z-index: 5;
          }
        }

        p {
          color: #1c2539;
          z-index: 5;
          width: 50%;
        }
      }
    }
  }

  // .carousel-control-next {
  //   span {
  //     // background: rgba($color: #fff, $alpha: 0.5);
  //     // border-radius: 50%;
  //     color: red;
  //   }

  //   .carousel-control-next-icon{
  //     background: rgba($color: #000, $alpha: 0.1);
  //   }
  // }
}
